<!--
 * @Author: mulingyuer
 * @Date: 2021-04-13 16:27:17
 * @LastEditTime: 2021-04-13 18:17:27
 * @LastEditors: mulingyuer
 * @Description: 查看链接
 * @FilePath: \saas-admin-vue\src\modules\lottery\components\lottery-list\ViewLinkDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="访问链接" :visible.sync="show" width="500px" class="dialog-vertical view-link-dialog"
    @open="careteQRCode">
    <div class="content">
      <div class="left">{{link}}</div>
      <div class="right">
        <img :src="qrCode" alt="二维码">
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" @click="onCopyLink">复制链接</el-button>
    </template>
  </el-dialog>
</template>
<script>
import QRious from "qrious";
import { copyText } from "@/base/utils/tool";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //查看的链接地址
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      qrCode: "", //二维码base64地址
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //生成二维码
    careteQRCode() {
      if (!this.$qr) {
        this.$qr = new QRious();
        this.$qr.background = "transparent";
        this.$qr.size = 150;
      }
      this.$qr.value = this.link;
      this.qrCode = this.$qr.toDataURL();
    },
    //复制链接
    onCopyLink() {
      copyText(this.link)
        .then(() => {
          this.$message.success("复制链接成功!");
          this.show = false;
        })
        .catch(() => {
          this.$message.error("复制链接成功!");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.view-link-dialog {
  .content {
    display: flex;
    .left {
      min-width: 0;
      flex-grow: 1;
    }
    .right {
      flex-shrink: 0;
      width: 115px;
      height: 115px;
      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
        object-fit: contain;
      }
    }
  }
}
</style>