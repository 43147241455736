<!--
 * @Author: mulingyuer
 * @Date: 2021-04-12 18:23:09
 * @LastEditTime: 2021-05-17 17:11:43
 * @LastEditors: mulingyuer
 * @Description: 抽奖列表
 * @FilePath: \saas-admin-vue\src\modules\lottery\views\lottery-list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page lottery-list" v-loading="loading">
    <div class="head">
      <div class="head-group-btn top-btn">
        <el-button v-if="postData.tab!=='deleted'" type="primary" size="small" @click="onAdd">新增</el-button>
      </div>
      <lottery-filter :filter-change="onFilter" :exportData="postData" />
    </div>
    <div class="list-content">
      <list-tabs v-model="postData.tab" :tabs="tabArr" @tab-click="handleTabClick" />
      <div class="list-btn">
        <el-button v-if="postData.tab==='deleted'" type="success" size="small" @click="onMultipleReduction">还原
        </el-button>
        <el-button size="small" type="danger" @click="onMultipleDelete">删除</el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <template v-for="th in forThead">
          <!-- 自定义表头 -->
          <el-table-column v-if="th.prop==='limit_text'" :key="th.prop" :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth">
            <template slot="header" slot-scope="scope">
              <div class="customize-header">
                {{th.label}}
                <el-tooltip class="item" effect="dark" content="每人每日抽奖数/每人抽奖总次数" placement="top">
                  <i class="icon el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="{row}">
              <span>{{row[th.prop]}}</span>
            </template>
          </el-table-column>
          <!-- 普通头 -->
          <el-table-column v-else :key="th.prop" :prop="th.prop" :label="th.label" :minWidth="th.minWidth"
            :sortable="th.sort" :fixed="th.fixed">
            <template slot-scope="{row}">
              <!-- 操作 -->
              <template v-if="th.prop==='operate'">
                <template v-if="postData.tab!=='deleted'">
                  <el-button type="text" size="mini" @click="onEdit(row.id)">编辑</el-button>
                  <el-button type="text" size="mini" @click="onDelete(row.id)">删除</el-button>
                  <el-divider direction="vertical" />
                  <el-dropdown @command="handleClickRow($event, row)">
                    <span style="color: #3576ff;font-size:12px" class="el-dropdown-link">更多</span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(item, index) in moreHandleList" :key="index"
                        :command="item.command">{{ item.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>

                <template v-if="postData.tab==='deleted'">
                  <el-button type="text" size="mini" @click="onReduction(row.id)">
                    还原</el-button>
                  <el-button type="text" size="mini" @click="onDelete(row.id)">删除</el-button>
                </template>
              </template>
              <template v-else-if="th.prop === 'status_text'">
                <list-status-text :text="row[th.prop]" />
              </template>
              <span v-else>{{row[th.prop]|placeholder}}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    <!-- 查看链接 -->
    <ViewLinkDialog v-model="openViewLinkDialog" :link="viewLink" />
  </div>
</template>
<script>
import LotteryFilter from "../components/lottery-list/Filter";
import Pagination from "@/base/components/Default/Pagination";
import ViewLinkDialog from "../components/lottery-list/ViewLinkDialog";
import { lotteryList, softDelete, hardDelete } from "../api/lottery-list";
import { putBack } from "../api/lottery-list";
import ListStatusText from '../../../base/components/List/ListStatusText'
export default {
  data() {
    return {
      loading: false, //数据加载中
      //提交的数据
      postData: {
        tab: "normal", //选中的tab name
      },
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "进行中", name: "running" },
        // { label: "已结束", name: "finishing" },
        { label: "回收站", name: "deleted" },
      ],
      //表头
      thead: {
        default: [
          { label: "ID", prop: "id", minWidth: 100 },
          { label: "名称", prop: "title", minWidth: 120 },
          { label: "个人抽奖次数", prop: "limit_text", minWidth: 150 },
          {
            label: "抽奖时间",
            prop: "time_text",
            minWidth: 300,
            sort: "custom",
          },
          { label: "状态", prop: "status_text", minWidth: 120 },
          { label: "操作", prop: "operate", minWidth: 170, fixed: "right" },
        ],
        deleted: [
          { label: "ID", prop: "id", minWidth: 100 },
          { label: "名称", prop: "title", minWidth: 120 },
          { label: "个人抽奖次数", prop: "limit_text", minWidth: 150 },
          {
            label: "抽奖时间",
            prop: "time_text",
            minWidth: 300,
            sort: "custom",
          },
          { label: "状态", prop: "status_text", minWidth: 120 },
          { label: "操作", prop: "operate", minWidth: 100, fixed: "right" },
        ],
      },
      tbody: [], //表格数据
      pageData: {}, //api请求回的数据
      activeLotteryIds: [], //选中的表格数据id
      // 下拉菜单配置
      moreHandleList: [
        { name: "抽奖记录", command: "onLotteryRecord" },
        { name: "奖品设置", command: "onPrizeConfig" },
        { name: "查看链接", command: "onOpenViewLinkDialog" },
      ],
      openViewLinkDialog: false, //是否打开查看链接弹窗
      viewLink: "", //查看的链接
    };
  },
  computed: {
    forThead() {
      if (this.postData.tab === "deleted") {
        return this.thead["deleted"];
      }
      return this.thead["default"];
    },
  },
  methods: {
    //获取抽奖列表数据
    getLotteryList(pageData) {
      this.loading = true;
      lotteryList(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      this.getLotteryList();
    },
    //tab切换事件
    handleTabClick() {
      //name已经v-model绑定到postData了
      Object.assign(this.postData, { page: 1 });
      this.getLotteryList();
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      const order_by = prop === "time_text" ? "start_time" : prop;
      this.postData = Object.assign(
        this.postData,
        { order_by, is_desc },
        { page: 1 }
      );
      this.getLotteryList();
    },
    //表格多选
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeLotteryIds = arr.map((item) => item.id);
      } else {
        this.activeLotteryIds = [];
      }
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getLotteryList();
    },
    // 点击下拉菜单
    handleClickRow(value, row) {
      if (value === "onOpenViewLinkDialog") {
        this[value](row.link);
      } else {
        this[value](row.id);
      }
    },
    //查看链接弹窗
    onOpenViewLinkDialog(link) {
      this.viewLink = link;
      this.openViewLinkDialog = true;
    },
    //单个删除
    onDelete(id, multiple = false) {
      let infoText = `您是否要删除${multiple ? "选中的" : "该条"}抽奖数据？`;
      let api = softDelete;
      if (this.postData.tab === "deleted") {
        infoText += "删除后将不可恢复！";
        api = hardDelete;
      }
      this.$confirm(infoText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          const postData = { id };
          api(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getLotteryList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多个删除
    onMultipleDelete() {
      if (this.activeLotteryIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onDelete(this.activeLotteryIds, true);
    },
    //单个还原
    onReduction(id, multiple = false) {
      this.$confirm(
        `是否还原${multiple ? "选中的" : "该条"}抽奖数据？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        }
      )
        .then(() => {
          this.loading = true;
          const postData = { id };
          putBack(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getLotteryList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多个还原
    onMultipleReduction() {
      if (this.activeLotteryIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onReduction(this.activeLotteryIds, true);
    },
    //新增
    onAdd() {
      this.$router.push({ name: "LotteryAddEditBasic" });
    },
    //编辑
    onEdit(id) {
      this.$router.push({ name: "LotteryAddEditBasic", query: { id } });
    },
    //奖品设置
    onPrizeConfig(id) {
      this.$router.push({ name: "PrizeConfig", params: { id } });
    },
    //抽奖记录
    onLotteryRecord(id) {
      this.$router.push({ name: "LotteryRecord", params: { id } });
    },
    // 列表导出
    onExport() {
      const exportData = {
        ...this.postData,
        page: this.pageData.current_page,
        page_size: this.pageData.per_page,
      };
      exportDocument(exportData)
        .then()
        .catch(() => {});
      // console.log("export", this.postData, this.activeLotteryIds);
    },
  },
  created() {
    //获取抽奖列表数据
    this.getLotteryList();
  },
  components: {
    ListStatusText,
    LotteryFilter,
    Pagination,
    ViewLinkDialog,
  },
};
</script>
<style lang="scss" scoped>
.lottery-list {
  .content {
    .customize-header {
      .icon {
        cursor: pointer;
        outline: none;
        color: #999;
      }
    }
  }
}
</style>